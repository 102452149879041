export default {
  palette: {
    primary: {
      light: "#fff",
      main: "#000",
      dark: "#333",
      contrastText: "#fff",
    },
    secondary: {
      light: "#338eb7",
      main: "#0072A5",
      dark: "#004f73",
      contrastText: "#fff",
    },
    tertiary: {
      light: "#338eb7",
      main: "#0072A5",
      dark: "#004f73",
      contrastText: "#fff",
    },
  },
  // the object to be spread
  spreadThis: {
    typography: {
      useNextVariants: true,
    },
    form: {
      textAlign: "center",
    },
    image: {
      margin: "20px auto",
      width: "60px",
    },
    pageTitle: {
      fontSize: "23px;",
      lineHeight: "35px",
      fontWeight: "regular",
      color: "#333",
    },
    textField: {
      margin: "10px auto",
    },
    button: {
      margin: "20px auto",
      position: "relative",
    },
    customError: {
      color: "red",
      fontSize: "0.8rem",
      marginTop: "10px",
    },
    progress: {
      position: "absolute",
    },
  },
};
